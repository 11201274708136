import React from "react"
import heroStyles from "./hero.module.scss"
import styles from "./notFoundHero.module.scss";
import { useStaticQuery, graphql } from "gatsby"
import TrimmedContainer from "./trimmed-container";
import Img from "gatsby-image"

const NotFoundHero = () => {
    const data = useStaticQuery(graphql`
      query {
        placeholderImage: file(relativePath: { eq: "QO_404.png" }) {
          childImageSharp {
            fluid(maxWidth: 1180, traceSVG: {  color: "#035c96" }) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `);

    return (
        <div className={heroStyles.wall}>
            <TrimmedContainer>
                <section className={heroStyles.container}>
                    <div className={heroStyles.lead}>
                        <h1 className={heroStyles.myName}>Not Found</h1>

                        <h2 className={heroStyles.myPurpose}>Oops that page does not exist.</h2>
                    </div>
                    <div className={styles.notfoundImgContainer}>
                        <Img alt="Quintin Orsmond" fluid={data.placeholderImage.childImageSharp.fluid} />
                    </div>
                </section>
            </TrimmedContainer>
            <div className={heroStyles.fullpageDivider}></div>
        </div>
    )
}

export default NotFoundHero;